<template>
    <v-container fill-height>
        <flash-item :notification="notification" :notification_message="notification_message"></flash-item>
        <v-layout justify-center wrap>
            <v-flex md12>
                <material-card title="Upload Products">
                <div class="table-wrapper">
                    <table class="excel-replica">
                        <tr>
                            <th>

                            </th>
                            <th v-for="col in columns">
                                {{ col }}
                            </th>
                        </tr>
                        <tr>
                            <th>Factory Name:</th>
                            <th colspan="26">
                                <v-text-field
                                        label=""
                                        required=""></v-text-field>
                            </th>

                        </tr>
                        <tr>
                            <th>Address:</th>
                            <th colspan="26">
                                <v-text-field
                                        label=""
                                        required=""></v-text-field>
                            </th>
                        </tr>
                        <tr>
                            <th>TEL:</th>
                            <th colspan="26">
                                <v-text-field
                                        label=""
                                        required=""></v-text-field>
                            </th>
                        </tr>
                        <tr>
                            <th>Contact Person:</th>
                            <th colspan="26">
                                <v-text-field
                                        label=""
                                        required=""></v-text-field>
                            </th>
                        </tr>
                        <tr>
                            <th>Mobile:</th>
                            <th colspan="26">
                                <v-text-field
                                        label=""
                                        required=""></v-text-field>
                            </th>
                        </tr>

                        <tr>
                            <th>Email:</th>
                            <th colspan="26">
                                <v-text-field
                                        label=""
                                        required=""></v-text-field>
                            </th>
                        </tr>

                        <tr>
                            <th>Website:</th>
                            <th colspan="26">
                                <v-text-field
                                        label=""
                                        required=""></v-text-field>
                            </th>
                        </tr>
                        <tr>
                            <th>Shipping Port:</th>
                            <th colspan="26">
                                <v-text-field
                                        label=""
                                        required=""></v-text-field>
                            </th>
                        </tr>
                        <tr>
                            <th>Category:</th>
                            <th colspan="26">
                                <v-text-field
                                        label=""
                                        required=""></v-text-field>
                            </th>
                        </tr>
                        <tr>
                            <th>Factory Code:</th>
                            <th colspan="26">
                                <v-text-field
                                        label=""
                                        required=""></v-text-field>
                            </th>
                        </tr>
                        <tr>
                            <th>WB:</th>
                            <th colspan="26">
                                <v-text-field
                                        label=""
                                        required=""></v-text-field>
                            </th>
                        </tr>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                        <tr>
                            <th v-for="header in product_headers">
                                {{ header }}
                            </th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </table>
                </div>
                </material-card>

            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import FlashItem from '../../../components/helper/FlashItem.vue'

    export default {
        components: {
            FlashItem
        },
        data() {
            return {
                notification: false,
                notification_message: '',
                columns: [],
                product_headers:[
                    "Item Number",
                    "FTY CODE",
                    "Photo",
                    "Description Include material",
                    "Packing",
                    "Item Size (cm)",
                    "Unit",
                    "Qty/Ctn",
                    "MOQ",
                    "FOB (USD)",
                    "Carton Measures (cm)",
                    "Carton CBM (m^2)",
                    "Gross W. (kg)",
                    "Net Weight (kg)",
                    "20\" Qty",
                    "40\" Qty",
                    "40HQ Qty",
                    "Category ID",
                    "Remarks",
                ],
                products: []
            }
        },
        created() {
            this.columns = Array.from({length: 26}, (_, i) => String.fromCharCode('A'.charCodeAt(0) + i));

            this.factory_id = parseInt(this.$route.params.factory_id);
            for(let i=0; i<=10; i++)
            {
                this.products.push({
                    item_number: "",
                    fty_code: "",
                    image: "",
                    description: "",
                    packing: "",
                    item_size: "",
                    unit: "",
                    item_size: "",
                    item_size: "",
                    item_size: "",
                    item_size: "",
                    item_size: "",

                })
            }
        },
        methods: {},
    }
    //TODO: Sebastian to fix, make look and work a little like excel
</script>

<style lang="scss" scoped>
    .table-wrapper {
        max-width: 100%;
        overflow: auto;
    }
    .excel-replica {

        background:white;
    tr {
    th {
        font-weight: bold;
        border:1px solid black !important;
        padding:2px;
    }
    td {
        border:1px solid black !important;
        padding:2px;
    }
    }
    }
</style>
